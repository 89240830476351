<template>
    <div class="container">
        <div class="row" v-if="journals.length > 1">
            <div class="col-md-4 mb-5" v-for="journal in journals" :key="journal.meta.issn">
                
                <div class="card h-100">
                    <router-link :to="`/journal/${journal.meta.issn}`">
                        <img class="card-img-top mt-3" :src="journal.meta.image" :alt="journal.meta.title">
                    </router-link>
                    <p class="text-center"><small>ISSN: {{journal.meta.issn}}</small></p>
                    <div class="card-body pt-0 mt-0">
                        <h4 class="card-title">{{journal.meta.title}}</h4>
                        <p class="card-text">{{journal.meta.description}}</p>
                    </div>
                    <div class="card-footer">
                        <router-link :to="`/journal/${journal.meta.issn}`" class="btn btn-primary">
                            <font-awesome-icon icon="search" />
                            {{translation.exploreJournal}}
                        </router-link>
                    </div>
                </div>
                
            </div>
        </div>
        
        <div class="row router-view" v-else-if="journalsList.length && journalsList[0].issues">
            <h5>{{ translation.recentIssues }}</h5>
            <div class="issues-view-wrapper">
                <router-view :journal="journalsList[0]" :doc="journals[0]" :limit="3" />
            </div>
            <div class="cta mt-3 mb-5">
                <router-link :to="`/journal/${journals[0].meta.issn}`" class="btn btn-primary">
                    <font-awesome-icon icon="search" />
                    {{translation.exploreJournal}}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        journals() {
            return [].concat(this.hrefs(`/journals/*`)).sort( (a,b) => a.meta.order - b.meta.order)
        },
        ... mapGetters(['journalsList'])
    }
}
</script>

<style lang="scss" scoped>
.router-view {
    .issues-view-wrapper > div {
        display: flex;
        // /deep/ .row {
        //     display: inline;
        // }
        /deep/ .card-header {
            display: none;
        }
        /deep/ .card {
            border: none;
        }
        .btn {
            margin: auto 30%;
        }
    }
    .cta {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>