<template>
    <div class="header-wrapper">
        <header class="py-5 mb-3" :style="{'background-image': `url(${doc.meta.banner || '/images/header-bg.jpg'})`}">
            <div v-if="doc.meta.customHeader" class="external" v-html="doc.meta.customHeader">
            </div>
            <div class="container h-100">
                <div class="row h-100 align-items-center">
                    <div class="col-lg-12">
                    </div>
                </div>
            </div>
        </header>
        <div class="container" v-if="hrefs('/journals').meta.links && hrefs('/journals').meta.links.length">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <ul class="nav justify-content-end">
                        <li class="nav-item" v-for="item in hrefs('/journals').meta.links" :key="`details-link-${item.type}`">
                            <router-link
                                class="nav-link"
                                :class="{active: $route.path.includes(item.type) }"
                                :to="{path: item.type}"
                            >
                                {{item.name}} »
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['doc']
}
</script>

<style lang="scss" scoped>

header {
    min-height: 300px;
    background-repeat: no-repeat;
    background-position: center; 
    background-size: cover;
    h1 {
        text-shadow: 1px 1px #000077;;
    }

}
.nav-link {
    &.active {
        // font-weight: 700;
        color:  black;
    }
}

</style>